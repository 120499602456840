<template>
  <div class="err_404">
    <div class="err_404_main">
      <img src="../assets/img/browser-404.png"/>
      <div class="err_404_txt">您访问的页面不存在，返回<router-link to="/">首页&gt;&gt;&gt;</router-link></div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  .err_404{
    padding: 200px;
    .err_404_main{
      text-align: center;
      img{
        width: 200px;
      }
      .err_404_txt{
        padding-top: 20px;
        font-size: 25px;
        a{
          color: #0a8cd2;
          text-decoration: underline;
        }
      }
    }
  }
</style>
